import { createRouter, createWebHistory } from 'vue-router'
import store, { getLocalStorageKey } from '@/store'
import { AUTH_TOKEN_COOKIE, clearCookie } from '@/helpers/cookie'

import AddImpactView from '@/views/AddImpactView.vue'
import Shareables from '@/views/Shareables.vue'
import SupportedProjects from '@/views/SupportedProjects.vue'
import HomeView from '@/views/HomeView.vue'
import AccountView from '@/views/AccountView.vue'
import LoginView from '@/views/Register/LoginView.vue'
import PreSignupView from '@/views/Register/PreSignupView.vue'
import SignupPartnerView from '@/views/Register/SignupPartnerView.vue'
import SignupEcommerceView from '@/views/Register/SignupEcommerceView.vue'
import SignupPersonalView from '@/views/Register/SignupPersonalView.vue'
import SignupFamilyView from '@/views/Register/SignupFamilyView.vue'
import DirectMarketplaceSignup from '@/views/Register/DirectMarketplaceSignup.vue'
import ForgotPasswordView from '@/views/Register/ForgotPasswordView.vue'
import ResetPasswordView from '@/views/Register/ResetPasswordView.vue'
import firebase from '@/auth/firebaseConfig.js'
import CheckoutSuccessView from '@/views/Register/CheckoutSuccessView.vue'
import PartnerSuccessView from '@/views/Register/PartnerSuccessView.vue'
import LivePageView from '@/views/LivePageView.vue'
import TeamView from '@/views/TeamView.vue'
import CanceledView from '@/views/CanceledView.vue'
import AdminHome from '@/views/Admin/AdminHome.vue'
import UpdateLedger from '@/views/Admin/UpdateLedger.vue'
import AccountsView from '@/views/Admin/AccountsView.vue'
import ChangeEmailView from '@/views/Admin/ChangeEmailView.vue'
import GiveRewardView from '@/views/Admin/GiveRewardView.vue'
import DeleteSubscriptionView from '@/views/Admin/DeleteSubscriptionView.vue'
import ManageCustomIntegrationView from '@/views/ManageCustomIntegrationView.vue'
import NetworkView from '@/views/NetworkView.vue'
import ManualOffsetsView from '@/views/ManualOffsetsView.vue'
import CheckoutView from '@/views/CheckoutView.vue'
import PaymentSuccessView from '@/views/PaymentSuccessView.vue'
import SelectPlanView from '@/views/SelectPlanView.vue'
import BadgesMain from '@/components/shareables/BadgesMain.vue'
import QrCode from '@/components/shareables/QrCode.vue'
import CustomerEngagementPortal from '@/views/CustomerEngagementPortal.vue'
import WelcomeView from '@/views/WelcomeView.vue'
import SignupB2B2C from '@/views/Register/SignupB2B2C.vue'
import SuccessB2B2C from '@/views/Register/SuccessB2B2C.vue'
import AnalyticsView from '@/views/AnalyticsView.vue'
import AnalyticsDashboard from '@/components/Analytics/AnalyticsDashboard.vue'
import MaintenanceView from '@/views/MaintenanceView.vue'
import OnboardingView from '@/views/OnboardingView.vue'
import ManageIntegrationsAutomationsView from '@/views/IntegrationsAutomations/ManageIntegrationsAutomationsView.vue'
import AddIntegrationAutomationView from '@/views/IntegrationsAutomations/AddIntegrationAutomationView.vue'
import AutomationsView from '@/views/IntegrationsAutomations/AutomationsView.vue'
import IntegrationsView from '@/views/IntegrationsAutomations/IntegrationsView.vue'
import ManageIntegrationsView from '@/views/IntegrationsAutomations/ManageIntegrationsView.vue'
import EmailIntegrationAlertView from '@/views/IntegrationsAutomations/EmailIntegrationAlertView.vue'
import EmailDataSyncView from '@/views/IntegrationsAutomations/EmailDataSyncView.vue'
import CarbonFootprintView from '@/views/CarbonFootprintView.vue'
import PublicLedgerView from '@/views/PublicLedgerView.vue'
import ShopifySetupView from '@/views/integrations/ShopifySetupView.vue'
import ShopifySetupSuccessView from '@/views/integrations/ShopifySetupSuccessView.vue'
import ShopifyConfigView from '@/views/integrations/ShopifyConfigView.vue'
import UploadShopifyPayoutView from '@/views/Admin/UploadShopifyPayout.vue'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    // Admin routes
    {
      meta: {
        title: 'Update Ledger',
        requiresAuth: true,
        admin: true,
      },
      name: 'UpdateLedger',
      path: '/admin/ledger',
      component: UpdateLedger,
    },
    {
      meta: {
        title: 'Accounts',
        requiresAuth: true,
        admin: true,
      },
      name: 'AccountsView',
      path: '/admin/accounts',
      component: AccountsView,
    },
    {
      meta: {
        title: 'Admin home',
        requiresAuth: true,
        admin: true,
      },
      name: 'AdminHome',
      path: '/admin/home',
      component: AdminHome,
    },
    {
      meta: {
        title: 'Change Email',
        requiresAuth: true,
        admin: true,
      },
      name: 'ChangeEmailView',
      path: '/admin/change-email',
      component: ChangeEmailView,
    },
    {
      meta: {
        title: 'Give Reward',
        requiresAuth: true,
        admin: true,
      },
      name: 'GiveRewardView',
      path: '/admin/reward',
      component: GiveRewardView,
    },
    {
      meta: {
        title: 'Upload Shopify Payout',
        requiresAuth: true,
        admin: true,
      },
      name: 'UploadShopifyPayoutView',
      path: '/admin/upload-shopify-payout',
      component: UploadShopifyPayoutView,
    },
    {
      meta: {
        title: 'Delete Account',
        requiresAuth: true,
        admin: true,
      },
      name: 'DeleteSubscriptionView',
      path: '/admin/delete-subscription',
      component: DeleteSubscriptionView,
    },
    // Non admin routes
    {
      meta: {
        requiresAuth: true,
        title: 'Your Impact',
      },
      name: 'home',
      path: '/',
      component: HomeView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Success',
      },
      name: 'Success',
      path: '/success',
      component: CheckoutSuccessView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Success',
      },
      name: 'PartnerSuccessView',
      path: '/partner/success/:relationsId',
      component: PartnerSuccessView,
    },
    {
      meta: {
        title: 'Success',
        requiresAuth: true,
      },
      name: 'SuccessB2B2C',
      path: '/success-personal',
      component: SuccessB2B2C,
    },
    {
      meta: {
        title: 'Login',
      },
      name: 'Login',
      path: '/login',
      component: LoginView,
    },
    {
      meta: {
        title: 'Signup',
      },
      name: 'PreSignupView',
      path: '/choose-signup',
      component: PreSignupView,
    },
    {
      meta: {
        title: 'Signup Partner',
      },
      name: 'SignupPartnerView',
      path: '/signup/partner/:id',
      component: SignupPartnerView,
    },
    {
      meta: {
        title: 'Signup E-commerce',
      },
      name: 'SignupEcommerce',
      path: '/e/signup',
      component: SignupEcommerceView,
    },
    {
      meta: {
        title: 'Signup Personal',
      },
      name: 'SignupPersonal',
      path: '/p/signup',
      component: SignupPersonalView,
    },
    {
      meta: {
        title: 'Signup Family',
      },
      name: 'SignupFamily',
      path: '/f/signup',
      component: SignupFamilyView,
    },
    {
      meta: {
        title: 'Signup',
        shopifyAuthRoute: true,
      },
      name: 'DirectMarketplaceSignup',
      path: '/direct-signup',
      component: DirectMarketplaceSignup,
    },
    {
      meta: {
        title: 'Onboarding',
        shopifyAuthRoute: true,
        hideGlobalLoading: true,
      },
      path: '/shopify',
      component: ShopifyConfigView,
      children: [
        {
          name: 'ShopifySetup',
          path: '',
          component: ShopifySetupView,
        },
        {
          name: 'ShopifySuccess',
          path: 'success',
          component: ShopifySetupSuccessView,
        },
      ],
    },
    {
      meta: {
        title: 'Signup',
      },
      name: 'SignupB2B2C',
      path: '/b/signup',
      component: SignupB2B2C,
    },
    {
      meta: {
        title: 'Checkout',
      },
      name: 'Checkout',
      path: '/checkout',
      component: CheckoutView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Checkout Success',
      },
      name: 'PaymentSuccessView',
      path: '/payment-success',
      component: PaymentSuccessView,
    },
    {
      meta: {
        title: 'Select Plan',
      },
      name: 'SelectPlanView',
      path: '/select-plan',
      component: SelectPlanView,
    },
    {
      meta: {
        title: 'Finish subscription',
      },
      name: 'FinishSubscription',
      path: '/finish-subscription',
      component: SelectPlanView,
    },
    {
      meta: {
        title: 'Upgrade Plan',
      },
      name: 'UpgradePlanView',
      path: '/upgrade-plan',
      component: SelectPlanView,
    },
    {
      meta: {
        title: 'Reset Password',
      },
      name: 'Reset Password',
      path: '/reset-password',
      component: ForgotPasswordView,
    },
    {
      meta: {
        title: 'Update Password',
      },
      name: 'Update Password',
      path: '/update-password',
      component: ResetPasswordView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Add Impact',
      },
      name: 'Add Impact',
      path: '/add-impact',
      component: AddImpactView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Tailored impact',
      },
      name: 'tailoredImpact',
      path: '/add-impact/tailored-impact',
      component: ManualOffsetsView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Integrations and automations',
      },
      name: 'IntegrationsAndAutomations',
      path: '/add-impact/manage-integrations-automations',
      component: ManageIntegrationsAutomationsView,
      children: [
        {
          meta: {
            requiresAuth: true,
            title: 'Integrations',
          },
          name: 'Integrations',
          path: 'integrations',
          component: IntegrationsView,
        },
        {
          meta: {
            requiresAuth: true,
            title: 'Automations',
          },
          name: 'Automations',
          path: 'automations',
          component: AutomationsView,
        },
      ],
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Add Integrations and automations',
      },
      name: 'AddIntegrationsAndAutomations',
      path: '/add-integrations-automations',
      component: AddIntegrationAutomationView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Manage Custom Integration',
      },
      name: 'ManageCustomIntegration',
      path: '/add-impact/integrations/custom-integrations/:customIntegrationId/manage',
      component: ManageCustomIntegrationView,
      props: true,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Manage integration',
      },
      name: 'ManageIntegrations',
      path: '/manage-integrations',
      component: ManageIntegrationsView,
      props: true,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Email integration sync',
      },
      name: 'EmailIntegrationSyncAlert',
      path: '/email-integration-sync-alert',
      component: EmailIntegrationAlertView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Email data sync',
      },
      name: 'EmailDataSync',
      path: '/email-data-sync',
      component: EmailDataSyncView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Analytics',
      },
      name: 'Analytics',
      path: '/analytics',
      component: AnalyticsView,
      children: [
        {
          meta: {
            requiresAuth: true,
            title: 'General',
          },
          name: 'general',
          path: '',
          component: AnalyticsDashboard,
        },
        {
          meta: {
            requiresAuth: true,
            title: 'Automations',
          },
          name: 'automations',
          path: 'automations',
          component: AnalyticsDashboard,
        },
        {
          meta: {
            requiresAuth: true,
            title: 'Subscription & tailored impact',
          },
          name: 'subscriptionAndTailoredImpact',
          path: 'subscription-tailored',
          component: AnalyticsDashboard,
        },
        {
          meta: {
            requiresAuth: true,
            title: 'Live page',
          },
          name: 'livePage',
          path: 'live-page',
          component: AnalyticsDashboard,
        },
        {
          meta: {
            requiresAuth: true,
            title: 'Customer engagement',
          },
          name: 'customerEngagement',
          path: 'customerEngagement',
          component: AnalyticsDashboard,
        },
      ],
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Shareables',
      },
      name: 'Shareables',
      path: '/shareables',
      component: Shareables,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Badges',
      },
      path: '/shareables/badges',
      name: 'badges',
      component: BadgesMain,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Qr Code',
      },
      path: '/shareables/qr',
      name: 'qr',
      component: QrCode,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Post purchase engagement',
      },
      path: '/shareables/post-purchase-engagement',
      name: 'Post purchase engagement',
      component: CustomerEngagementPortal,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Your impact',
      },
      name: 'YourImpact',
      path: '/your-impact',
      component: HomeView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Team',
      },
      name: 'Team',
      path: '/team',
      component: TeamView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Network',
      },
      name: 'Network',
      path: '/network',
      component: NetworkView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Supported Projects',
      },
      name: 'SupportedProjects',
      path: '/projects',
      component: SupportedProjects,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Public Ledger',
      },
      name: 'PublicLedger',
      path: '/ledger',
      component: PublicLedgerView,
    },
    {
      meta: {
        requiresAuth: true,
        title: 'Account',
      },
      name: 'Account',
      path: '/account',
      component: AccountView,
    },
    {
      name: 'LivePage',
      path: '/dashboard/:id/:companyname',
      component: LivePageView,
    },
    {
      meta: {
        title: 'Subscription Canceled',
      },
      name: 'CanceledView',
      path: '/canceled',
      component: CanceledView,
    },
    {
      meta: {
        title: 'Welcome view',
      },
      name: 'WelcomeView',
      path: '/welcome',
      component: WelcomeView,
    },
    {
      meta: { requiresAuth: true },
      name: 'fallback',
      path: '/:catchAll(.*)',
      redirect: '/',
    },
    {
      meta: { title: 'Maintenance' },
      name: 'Maintenance',
      path: '/maintenance',
      component: MaintenanceView,
    },
    {
      meta: {
        title: 'Onboarding',
        requiresAuth: true,
      },
      name: 'Onboarding',
      path: '/onboarding',
      component: OnboardingView,
    },
    {
      meta: {
        title: 'Signup Invite',
      },
      name: 'SignupInvite',
      path: '/signup/user',
      component: SignupEcommerceView,
    },
    {
      meta: {
        title: 'Carbon report',
        requiresAuth: true,
      },
      name: 'CarbonReport',
      path: '/carbon-report',
      component: CarbonFootprintView,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('closeDialog')

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const adminRoute = to.matched.some((record) => record.meta.admin)
  const firebaseUser = await firebase.getCurrentUser()
  const account = store.state.account

  if (to.name === 'LivePage') {
    const companyName = to.params.companyname
    document.title = `${companyName}'s Impact with Greenspark`
  } else {
    document.title = 'title' in to.meta ? `Greenspark - ${to?.meta?.title as string}` : 'Greenspark'
  }

  // if protected route, there's an authentication account, but the account has no widgets and coming from/to the partner success page then navigate as usual
  if (
    requiresAuth &&
    firebaseUser &&
    !account?.widgets?.length &&
    to.path.startsWith('/partner/success') &&
    from.path.startsWith('/signup/partner')
  ) {
    next()
    return
  }

  // if protected route, there's an authentication account, but the account has no widgets and coming from/to the success page then navigate as usual
  if (
    requiresAuth &&
    firebaseUser &&
    !account?.widgets?.length &&
    to.path === '/success' &&
    from.path === '/success'
  ) {
    next()
    return
  }

  // if protected route however there's no authentication account then navigate to login
  if (requiresAuth && !firebaseUser) {
    clearCookie(AUTH_TOKEN_COOKIE)
    if (window && window.localStorage) {
      window.localStorage.removeItem(getLocalStorageKey())
    }
    next('/login')
  }

  // if protected route, there's an authentication account, but the account has no widgets and NOT coming from/to the success page then navigate to login
  if (
    requiresAuth &&
    firebaseUser &&
    !account?.widgets?.length &&
    to.path !== '/success' &&
    from.path !== '/success'
  ) {
    next('/login')
  }
  // if protected route, there's an authenticated account, but never finished, nor skipped the onboarding flow (user wants to manually navigate away from the onboarding flow)
  if (
    requiresAuth &&
    firebaseUser &&
    to.path !== '/onboarding' &&
    !store.state.onboardingModule.isOnboardingSkipped
  ) {
    next('/onboarding')
  }

  // if protected route, there's an authenticated account, navigates to /onboarding, but already finished, or skipped the onboarding flow (user wants to manually navigate to the onboarding flow)
  if (
    requiresAuth &&
    firebaseUser &&
    to.path === '/onboarding' &&
    store.state.onboardingModule.isOnboardingSkipped
  ) {
    next(from.fullPath)
  }

  if (
    requiresAuth &&
    firebaseUser &&
    to.path === '/carbon-report' &&
    !store.state.featureFlagsModule.carbonFootprint
  ) {
    next(from.fullPath)
  }

  // if protected route, there is an authenticated account, navigates to root path, but is a B2B2C account
  if (requiresAuth && firebaseUser && to.path === '/' && store.getters.getIfB2B2C) {
    document.title = 'Greenspark - Impact wallet'
  }

  if (adminRoute && account.accountType !== 'admin') {
    next('/')
  }

  const requiresOverlay = store.getters['getShouldDisplayShopifyOverlay']
  if (requiresOverlay && !to.meta.shopifyAuthRoute) {
    next({ name: 'ShopifySetup', query: to.query })
    return
  }

  next()
})

export default router
