<template>
  <div class="stat" :style="offsetType ? { backgroundImage: impactBackgrounds[offsetType] } : ''">
    <div class="text-number-wrapper">
      <p class="number mb-0">
        {{ formatNumberByLocale(modelValue ?? 0) }}
      </p>
      <p class="mb-0">
        {{ label || t(`${offsetType}.label`) }}
      </p>
      <TooltipImpact
        v-if="!!transferredImpact || !!relationImpact"
        :transferred-text="transferredText"
        :partner-text="relationImpactText"
        :transferred-impact="transferredImpact"
      />
    </div>
    <div />
  </div>
</template>

<script lang="ts">
import TooltipImpact from '@/components/tools/TooltipImpact.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { OffsetType } from '@/helpers/interfaces'
import type { TranslateResult } from 'vue-i18n'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { IMPACT_BACKGROUND_ASSET_MAP } from '@/helpers/projects'

export default defineComponent({
  name: 'TopStatCard',
  components: {
    TooltipImpact,
  },
  mixins: [Utils],
  computed: {
    transferredText(): TranslateResult | undefined {
      return this.transferredImpact
        ? this.tc('transferredText', this.transferredImpact || 0, {
            amount: this.formatNumberByLocale(this.transferredImpact || 0),
          })
        : undefined
    },
    relationImpactText(): TranslateResult | undefined {
      return this.relationImpact
        ? this.tc(`${this.offsetType}.tooltip`, this.relationImpact || 0, {
            amount: this.formatNumberByLocale(this.relationImpact || 0),
          })
        : undefined
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`TopStatCard.${key}`, params ?? {})
    },
    tc(key: string, count: number, params?: { [key: string]: string | number }) {
      return this.$t(`TopStatCard.${key}`, count, params ?? {})
    },
  },
  props: {
    modelValue: {
      type: Number,
    },
    label: {
      type: String as PropType<TranslateResult>,
    },
    offsetType: {
      type: String as PropType<OffsetType>,
    },
    transferredImpact: {
      type: Number,
    },
    relationImpact: {
      type: Number,
    },
  },
  data() {
    return {
      impactBackgrounds: IMPACT_BACKGROUND_ASSET_MAP,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.stat {
  display: flex;
  justify-content: center;
  border-radius: 7px;
  padding: 16px 20px 16px;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-color: var(--ui-beige);
  background-repeat: no-repeat;
  flex: 1 1 0;
}

.text-number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}

.number {
  color: inherit;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 3px;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .stat {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
