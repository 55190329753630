<template>
  <section class="impact-settings-wrapper">
    <v-form v-model="isFormValid">
      <div class="impact-settings">
        <ImpactSettingCard
          v-for="(project, index) in projects"
          :key="index"
          v-model="localValues[project.projectId]"
          v-observe-intersection:[index]="setVisibleIndex"
          :position="index"
          :project-id="project.projectId"
          :is-button-active="!!localValues[project.projectId]"
          :single-selection="singleSelection"
          :max-value="getMaxValueForProject(project.projectId)"
          @update:model-value="onUpdate($event, project.projectId)"
        />
      </div>
    </v-form>
    <div class="markers">
      <div
        v-for="(_, index) in projects"
        :key="index"
        :class="['marker-dot', { visible: index === lastVisibleCardIndex }]"
      />
    </div>
  </section>
</template>

<script lang="ts">
import ImpactSettingCard from '@/components/onboarding/ImpactSettingCard.vue'
import { PARTNER_PROJECTS } from '@/helpers/constants'
import { PROJECT_ID_IMPACT_TYPE_MAP } from '@/helpers/projects'
import { includes } from '@/helpers/parsers'
import { defineComponent } from 'vue'

import type { AutomationOffset } from '@/store/integrations'
import type { ProjectId } from '@/helpers/constants'
import type { Project } from '@/store/projects'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ImpactSettings',
  emits: ['update:model-value', 'onValidity'],
  components: {
    ImpactSettingCard,
  },
  data() {
    return {
      localValues: {},
      isFormValid: true,
      lastVisibleCardIndex: 0,
    } as {
      localValues: Record<string, number>
      isFormValid: boolean
      lastVisibleCardIndex: number
    }
  },
  computed: {
    projects(): Project[] {
      // we only support these projects for now for the partner features
      if (window.location.pathname.startsWith('/partner/success'))
        return (this.appProjects || []).filter((project) =>
          includes(PARTNER_PROJECTS, project.projectId),
        )
      return this.appProjects || []
    },
    appProjects(): Project[] {
      return this.$store.getters['getAppProjects']
    },
  },
  created() {
    if (this.modelValue) {
      this.localValues = this.modelValue.reduce((acc, curr) => {
        if (!curr.projectId) throw new Error('projectId is missing')
        acc[curr.projectId] = curr.amount
        return acc
      }, {})
    }
  },
  methods: {
    setVisibleIndex(payload: number) {
      this.lastVisibleCardIndex = payload
    },
    onUpdate(value: number, projectId: ProjectId) {
      if (this.singleSelection && value) this.setActiveButton(value, projectId)
      this.$emit(
        'update:model-value',
        Object.entries(this.localValues).map(([key, value]) => ({
          type: PROJECT_ID_IMPACT_TYPE_MAP[key],
          amount: value,
          projectId: key,
        })),
      )
    },
    setActiveButton(value: number, projectId: ProjectId) {
      Object.keys(this.localValues).forEach((key) => {
        if (key === projectId) {
          this.localValues[key] = 1
        } else if (this.localValues[key]) {
          this.localValues[key] = 0
        }
      })
    },
    getMaxValueForProject(projectId: ProjectId): number {
      const maxValue = this.maxValues.find((maxValue) => maxValue.projectId === projectId)
      return maxValue?.amount ? maxValue.amount : Number.MAX_SAFE_INTEGER
    },
    onFormValidChange() {
      this.$emit('onValidity', this.isFormValid)
    },
    onValueChange() {
      this.localValues =
        this.modelValue?.reduce((acc, curr) => {
          if (!curr.projectId) throw new Error('projectId is missing')
          acc[curr.projectId] = curr.amount
          return acc
        }, {}) ?? []
    },
  },
  props: {
    modelValue: {
      type: Array as PropType<AutomationOffset[]>,
    },
    singleSelection: {
      type: Boolean,
    },
    maxValues: {
      default: () => [],
      type: Array as PropType<Omit<Required<AutomationOffset>, 'type' | 'source'>[]>,
    },
  },
  watch: {
    isFormValid: [
      {
        handler: 'onFormValidChange',
      },
    ],
    modelValue: [
      {
        handler: 'onValueChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.impact-settings {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  column-gap: 24px;
  row-gap: 14px;
}

.impact-settings-wrapper {
  position: relative;
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px 0 20px;
  gap: 15px;
}

.marker-dot {
  height: 10px;
  width: 10px;
  background-color: var(--gray-light-20);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  border: 1px solid var(--gray-light);
  transition: all ease 0.3s;
}

.marker-dot.visible {
  background-color: var(--ui-green-light);
  border: none;
  transition: all ease 0.3s;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .markers {
    display: none;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .impact-settings {
    overflow: visible;
    flex-wrap: wrap;
  }
}
</style>
