import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f333144"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stat-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopStatCard = _resolveComponent("TopStatCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['top-stat-wrapper', _ctx.theme])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TopStatCard, {
        class: "earth-positive-card",
        label: _ctx.t('earth_positive'),
        "model-value": _ctx.displayedMonthsEarthPositive
      }, null, 8, ["label", "model-value"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalImpacts, ({ type, amount }, index) => {
        return (_openBlock(), _createBlock(_component_TopStatCard, {
          key: index,
          "offset-type": type,
          "model-value": amount,
          "transferred-impact": _ctx.getTransferredImpact(type),
          "relation-impact": _ctx.getRelationImpact(type)
        }, null, 8, ["offset-type", "model-value", "transferred-impact", "relation-impact"]))
      }), 128))
    ])
  ], 2))
}