<template>
  <v-dialog width="1175" v-model="isOpen" persistent :fullscreen="$vuetify.display.xs">
    <v-card class="card d-flex flex-column">
      <div class="popup-header">
        <p class="popup-title">
          {{
            selectedProducts && selectedProducts?.length > 1
              ? t('choose_bulk_impact', { count: selectedProducts?.length.toString() ?? '0' })
              : t('choose_impact')
          }}
        </p>
        <button class="close-button" @click.prevent="close" variant="plain"></button>
      </div>
      <div class="filter-button-list">
        <button
          v-for="(category, index) in categories"
          :key="index"
          :class="['filter-button', { 'is-active': category === selectedCategory }]"
          @click="searchByCategory(category)"
        >
          {{ t(`projects.${category}`) }}
        </button>
      </div>
      <v-form v-model="isFormValid">
        <div class="impact-settings">
          <ImpactSettingCard
            v-for="(project, index) in filteredProjects"
            :key="index"
            v-model="localValues[project.projectId]"
            v-observe-intersection:[index]="setVisibleIndex"
            :position="index"
            :project-id="project.projectId"
            :is-button-active="!!localValues[project.projectId]"
            :max-value="getMaxValueForProject(project.projectId)"
            @update:model-value="onUpdate"
          />
        </div>
      </v-form>
      <div class="markers">
        <div
          v-for="(_, index) in filteredProjects"
          :key="index"
          :class="['marker-dot', { visible: index === lastVisibleCardIndex }]"
        />
      </div>
      <div class="totals-wrapper section">
        <p class="cart-items-text">
          {{ cartText }}
        </p>
        <p class="total-text">
          {{
            t('total', {
              price: userCurrencySymbol + total,
            })
          }}
        </p>
      </div>
      <gs-button
        @click="handleSave"
        :type="automationId ? 'primary' : 'monochrome'"
        capitalized
        :uppercased="false"
        :size="automationId ? 'large' : 'normal'"
        full-width
      >
        {{ t(automationId ? 'save_impact' : 'set_impact') }}
      </gs-button>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { AutomationOffset } from '@/store/integrations'
import ImpactSettingCard from '@/components/onboarding/ImpactSettingCard.vue'
import type { ProjectId } from '@/helpers/constants'
import { PROJECT_ID_IMPACT_TYPE_MAP } from '@/helpers/projects'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { Project } from '@/store/projects'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { Decimal } from 'decimal.js'
import type { TranslateResult } from 'vue-i18n'
import type { AutomationTrigger } from '@/helpers/interfaces'
import { OFFSET_TYPES, type OffsetType } from '@/helpers/interfaces'

export default defineComponent({
  name: 'ImpactSettingsPopup',
  emits: ['close', 'save'],
  mixins: [Utils],
  components: {
    ImpactSettingCard,
  },
  data() {
    return {
      isOpen: true,
      localValues: {},
      impactSettings: [],
      isFormValid: true,
      lastVisibleCardIndex: 0,
      selectedCategory: 'all',
      categories: ['all', ...OFFSET_TYPES],
    } as {
      isOpen: boolean
      localValues: Record<string, number>
      impactSettings: AutomationOffset[]
      isFormValid: boolean
      lastVisibleCardIndex: number
      selectedCategory: OffsetType | 'all'
      categories: (OffsetType | 'all')[]
    }
  },
  computed: {
    projects(): Project[] {
      return this.$store.getters['getAppProjects']
    },
    filteredProjects(): Project[] {
      return this.selectedCategory === 'all'
        ? this.projects
        : this.projects.filter((project) => project.type === this.selectedCategory)
    },
    selectedTrigger(): AutomationTrigger {
      return 'offsetPerProduct'
    },
    getProjectById(): (projectId: string) => Project {
      return this.$store.getters['getProjectById']
    },
    getProjectPriceById(): (projectId: string) => number {
      return this.$store.getters['getProjectPriceById']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    total(): number {
      let total = 0
      this.impactSettings.forEach(({ amount, projectId }) => {
        if (!projectId) return
        total = new Decimal(this.getProjectPriceById(projectId as string))
          .times(amount || 0)
          .add(total)
          .toNumber()
      })
      return total
    },
    cartText(): string | TranslateResult {
      if (this.impactSettings.every(({ amount }) => !amount)) {
        return ''
      } else {
        let impactText = ''
        const impactSettingWithValues: AutomationOffset[] = this.impactSettings.filter(
          ({ amount }) => amount,
        )
        const accumulatedImpactSettingsByType = impactSettingWithValues.reduce(
          (acc, { amount, type }) => {
            const itemByType = acc.find((item) => item.type === type)
            const amountToAdd = amount || 0
            if (!itemByType) {
              acc.push({ type, amount: amountToAdd })
            } else {
              itemByType.amount = itemByType.amount ? itemByType.amount + amountToAdd : amountToAdd
            }
            return acc
          },
          [] as AutomationOffset[],
        )
        const lastItemIndex = accumulatedImpactSettingsByType.length - 1
        accumulatedImpactSettingsByType.forEach(({ amount, type }, index) => {
          if (amount && !index) {
            impactText += this.tc(`${this.snakeCase(this.selectedTrigger ?? '')}.${type}`, amount)
          } else if (amount && lastItemIndex === 1) {
            impactText +=
              ', and ' + this.tc(`${this.snakeCase(this.selectedTrigger ?? '')}.${type}`, amount)
          } else if (amount && lastItemIndex > 1)
            impactText +=
              (index > 1 ? ', and ' : ', ') +
              this.tc(`${this.snakeCase(this.selectedTrigger ?? '')}.${type}`, amount)
        })
        const textValues = { amount: impactText, type: '' }

        return this.capitalizeFirstLetter(
          this.$t(`SelectProjects.${this.snakeCase(this.selectedTrigger ?? '')}.text`, textValues),
        )
      }
    },
  },
  methods: {
    t(key: string, params?: Record<string, string>) {
      return this.$t(`ProductTable.${key}`, params ?? {})
    },
    tc(key: string, count: number) {
      return this.$t(`SelectProjects.${key}`, count)
    },
    close() {
      this.isOpen = !this.isOpen
      this.$emit('close')
    },
    setVisibleIndex(payload: number) {
      this.lastVisibleCardIndex = payload
    },
    onUpdate() {
      this.impactSettings = Object.entries(this.localValues).map(([key, value]) => ({
        type: PROJECT_ID_IMPACT_TYPE_MAP[key],
        amount: value,
        projectId: key,
      })) as AutomationOffset[]
    },
    getMaxValueForProject(projectId: ProjectId): number {
      const maxValue = this.maxValues.find((maxValue) => maxValue.projectId === projectId)
      return maxValue?.amount ? maxValue.amount : Number.MAX_SAFE_INTEGER
    },
    searchByCategory(category: OffsetType | 'all') {
      this.selectedCategory = category
    },
    handleSave() {
      this.$emit('save', {
        offsets: this.impactSettings.filter((impact) => !!impact.amount),
        selectedProducts: this.selectedProducts,
      })
      this.close()
    },
  },
  props: {
    maxValues: {
      default: () => [],
      type: Array as PropType<Omit<Required<AutomationOffset>, 'type' | 'source'>[]>,
    },
    selectedProducts: {
      type: Array as PropType<string[]>,
    },
    automationId: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.card {
  padding: 16px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 32px;
  }

  :deep(.gs-button) {
    overflow: visible;
    padding: 19px 0 !important;
  }
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-right: 40px;
}

.impact-settings {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  column-gap: 24px;
  row-gap: 14px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    overflow: visible;
    flex-wrap: wrap;
  }

  :deep(.select-project-wrapper) {
    padding-bottom: 0;
  }
}

.impact-settings-wrapper {
  position: relative;
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px 0 20px;
  gap: 15px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: none;
  }
}

.marker-dot {
  height: 10px;
  width: 10px;
  background-color: var(--gray-light-20);
  box-shadow: var(--box-shadow);
  border-radius: 50%;
  border: 1px solid var(--gray-light);
  transition: all ease 0.3s;
}

.marker-dot.visible {
  background-color: var(--ui-green-light);
  border: none;
  transition: all ease 0.3s;
}

.totals-wrapper {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 24px;
  margin: 21px 0;
  border-top: 1px solid var(--gray-light-CC);
}

.cart-items-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #7c7c7c;
  margin-bottom: 10px;
}

.total-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0;
}

.filter-button-list {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.filter-button {
  border-radius: var(--border-radius-big);
  padding: 5px 8px;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray-light-FF);
  background-color: #7c7c7c;
}

.filter-button.is-active {
  background-color: var(--ui-black);
  cursor: auto;
}
</style>
