<template>
  <div class="free-plan-card">
    <div :class="['free-plan-card-header', { 'is-open': isOpen }]">
      <div class="header-text-wrapper">
        <h3 class="header-title">
          {{ t('header_title') }}
        </h3>
        <p class="header-desc">
          {{ t('header_desc') }}
        </p>
      </div>
      <div class="header-button-wrapper">
        <gs-button :disabled="isFreeBusinessPlanActive" size="large" @click.prevent="selectPlan">
          {{ isFreeBusinessPlanActive ? t('current') : t('start') }}
        </gs-button>
        <button
          :class="['toggle-content-button', { 'is-open': isOpen }]"
          @click.prevent="toggleContent"
        >
          {{ t('see_details') }}
        </button>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="isOpen" :class="['content']">
        <ul class="feature-list">
          <li
            v-for="(item, index) in featureListItems"
            :key="index"
            class="feature-item"
            v-html="t(`${featuresTranslationKey}.${index}`, { listOfPrices })"
          />
        </ul>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { eventBus } from '@/main'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'
import messages from '@/i18n/langs'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import type { AuthUser } from '@/store'
import type { Integration } from '@/store/integrations'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { OffsetType } from '@/helpers/interfaces'

export default defineComponent({
  name: 'FreePlanCard',
  mixins: [Utils],
  data() {
    return {
      isOpen: false,
      location: '',
    }
  },
  computed: {
    isFreeBusinessPlanActive(): boolean {
      return this.getActiveSubscriptionItem?.product === 'freeBusiness'
    },
    featuresTranslationKey() {
      return 'features_new'
    },
    featureListItems() {
      return messages[this.$i18n.locale].FreePlanCard.features_new
    },
    authUser(): AuthUser {
      return this.$store.getters['getAuthUser']
    },
    getLastShopifyIntegration(): Integration {
      return this.$store.getters['getLastShopifyIntegration']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
    currencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    verbosePrices(): Record<OffsetType, string> {
      return {
        trees: this.$store.getters['getVerboseActionPricingByType']('trees', true),
        kelp: this.$store.getters['getVerboseActionPricingByType']('kelp', true),
        carbon: this.$store.getters['getVerboseActionPricingByType']('carbon', true),
        plastic: this.$store.getters['getVerboseActionPricingByType']('plastic', true),
      }
    },
    listOfPrices(): string {
      return OFFSET_TYPES.map((t) => this.verbosePrices[t]).join(', ')
    },
    shouldNavigateToCheckout(): boolean {
      return (
        this.$route.query.restartSubscription === 'true' ||
        this.$route.path === '/finish-subscription'
      )
    },
    shouldHaveTrial(): boolean {
      return this.$route.path === '/select-plan' || this.$route.path === '/finish-subscription'
    },
  },
  async created() {
    try {
      this.location = await this.getLocation()
    } catch (error) {
      this.location = ''
    }
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`FreePlanCard.${key}`, params ?? {})
    },
    toggleContent() {
      this.isOpen = !this.isOpen
    },
    async selectPlan() {
      // signup from marketplace
      if (this.$route.name === 'DirectMarketplaceSignup') {
        eventBus.emit('planSelected', { planType: 'freeBusiness' })
      } else {
        // if restartSubscription query param is set then the user is restarting the subscription, so we need to set the current user
        if (this.shouldNavigateToCheckout) {
          await this.$router.push({
            path: '/checkout',
            query: {
              type: 'subscription',
              plan: 'freeBusiness',
              total: '0',
              trial: this.shouldHaveTrial ? 'true' : 'false',
            },
          })
        } else {
          // regular signup. total as 0 as we use a trial period hence we don't add a fee right away
          await this.$router.push({
            path: '/e/signup',
            query: {
              type: 'subscription',
              plan: 'freeBusiness',
              total: '0',
            },
          })
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.free-plan-card {
  background-color: white;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-light);
  width: 100%;
  max-width: 1220px;
  margin: 0 auto 80px;
  padding: 24px;
}

.free-plan-card-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transition: all ease-in-out 0.6s;
}

.free-plan-card-header.is-open {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--gray-light-E4);
  transition: none;
}

.header-text-wrapper {
  margin-bottom: 24px;
}

.header-title {
  font-size: 30px;
  margin-bottom: 8px;
}

.header-desc {
  font-size: 14px;
  max-width: 60ch;
  margin-bottom: 0px;
}

.header-button-wrapper {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.toggle-content-button {
  color: var(--ui-gray);
  font-size: 16px;
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: underline;
}

.toggle-content-button::after {
  display: block;
  content: '';
  border: 6px solid transparent;
  border-top: 6px solid var(--font-color-primary);
  border-bottom: 0;
  transition: 0.3s ease-in-out;
}

.toggle-content-button.is-open::after {
  transform: rotate(180deg);
}

.toggle-content-button-icon {
  color: inherit;
  font-size: inherit;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 18px;
  padding-left: 30px;
  position: relative;
}

.feature-item::before {
  content: '';
  background: url('../../assets/icons/check_green.svg') no-repeat center/contain;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 0;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .free-plan-card {
    padding: 32px;
  }

  .free-plan-card-header {
    justify-content: space-between;
  }

  .header-button-wrapper {
    width: auto;
  }

  .header-text-wrapper {
    margin-bottom: 0;
  }

  .header-desc {
    font-size: 18px;
  }

  .free-plan-card-header.is-open {
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
