import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61e7cb24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "impact-card-wrapper" }
const _hoisted_2 = { class: "impact-card-amount" }
const _hoisted_3 = { class: "impact-card-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      class: _normalizeClass(['impact-card-list', _ctx.theme])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impacts, (impact, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "impact-card",
          style: _normalizeStyle({ backgroundImage: _ctx.impactBackgrounds[impact.type] })
        }, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.formatTotalAmounts(impact.amount)), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.tc(impact.type, impact.amount)), 1)
        ], 4))
      }), 128))
    ], 2)
  ]))
}