<template>
  <div class="dialog-container">
    <div class="impact-action-header">
      <div class="image-wrapper">
        <v-icon class="impact-action-icon material-icons-outlined">
          {{ iconName }}
        </v-icon>
      </div>
      <h2 class="impact-action-title">
        {{ title }}
      </h2>
      <p class="date">
        {{ date }}
      </p>
    </div>

    <ImpactCards :impacts="impactAction?.action.impacts" />

    <template v-if="!!impactAction?.action.lineItems && !!impactAction?.action.lineItems.length">
      <div class="info-container">
        <h3 class="product-info-title">
          <v-icon class="product-info-title-icon"> mdi-shape-outline </v-icon
          >{{ t('product_info_title') }}
        </h3>

        <ul class="product-list">
          <li
            v-for="(product, index) in impactAction.action.lineItems"
            :key="index"
            class="product-list-item"
          >
            <div class="product-info">
              <img
                :src="product.image || require('@/assets/images/leaf.svg')"
                :alt="product.title"
                height="70"
                width="70"
                class="product-image"
              />
              <p class="product-name">
                {{ product.title }}
              </p>
            </div>
            <template v-if="product.impacts.length">
              <div class="product-impacts">
                <ImpactPill
                  v-for="(impact, key) in product.impacts.filter((f) => f.amount > 0)"
                  :key="key"
                  :impact="{ type: impact.type || 'multiple', amount: impact.amount }"
                />
              </div>
            </template>
          </li>
        </ul>
      </div>
    </template>
    <LoaderBlock v-if="loading" background-color="transparent" />

    <button class="info-container brand" @click="openSelectedBrand">
      <h3 class="overall-impact-by-brand-title">
        <GreenSparkLogo class="overall-icon" />
        {{
          isImpactFromGreenSpark
            ? 'Your impact'
            : t('overall_impact_title', {
                companyName: brand.companyName,
              })
        }}
      </h3>
      <div class="impact-by-brand-container">
        <img
          v-if="brand.imageURL"
          height="80"
          width="80"
          class="brand-logo"
          :src="brand.imageURL"
          :alt="brand.companyName + 'logo'"
        />
        <div class="impact-wrapper">
          <ImpactPill
            v-for="(impact, index) in impactsForPills"
            :key="index"
            :impact="{ type: impact.type || 'multiple', amount: impact.amount }"
          />
        </div>
      </div>
      <p class="view-details-text">
        {{ isImpactFromGreenSpark ? t('view_your_impact') : t('view_details') }}
      </p>
    </button>
  </div>
</template>

<script lang="ts">
import type {
  Emptyable,
  Impact,
  ImpactAction,
  ImpactByBrand,
  TotalImpact,
} from '@/helpers/interfaces'
import ImpactCards from '@/components/common/ImpactCards.vue'
import { getAccountImpactsByBrands } from '@api/index'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import GreenSparkLogo from '@/components/icons/GreenSparkLogo.vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'ImpactActionDetailDialog',
  emits: ['open-brand-detail'],
  components: { LoaderBlock, ImpactPill, ImpactCards, GreenSparkLogo },
  data() {
    const userImpacts: Impact[] = []
    const brand: ImpactByBrand = {
      companyName: '',
      imageURL: '',
      impacts: [],
      userId: '',
      profileDescription: '',
      url: '',
    }

    return {
      brand,
      loading: false,
      userImpacts,
    }
  },
  computed: {
    iconName(): string {
      switch (this.impactAction?.type) {
        case 'ORDER_CREATED':
          return 'mdi-basket-outline'
        case 'PER_SUBSCRIBER':
          return 'mdi-email-check-outline'
        case 'PER_PRODUCT_REVIEW':
          return 'mdi-message-draw'
        case 'PER_COMPANY_REVIEW':
          return 'mdi-message-draw'
        case 'ONE_OFF':
          return 'mdi-flash-outline'
        case 'SUBSCRIPTION':
          return 'mdi-tag-heart-outline'
        case 'REFERRAL':
          return 'mdi-bullhorn-variant-outline'
        case 'INVOICE':
          return 'mdi-cart-outline'
        case 'GIFT':
          return 'mdi-gift-outline'
        case 'REDEEM_LOYALTY_POINTS':
          return 'mdi-star'
        default:
          return 'mdi-basket-outline'
      }
    },
    isImpactFromGreenSpark(): boolean {
      return (
        this.impactAction?.type === 'ONE_OFF' ||
        this.impactAction?.type === 'REFERRAL' ||
        this.impactAction?.type === 'SUBSCRIPTION'
      )
    },
    impactsForPills(): {
      type: Emptyable<'trees' | 'carbon' | 'plastic' | 'kelp'>
      amount: number
    }[] {
      if (this.isImpactFromGreenSpark) {
        return this.userImpacts.filter((f) => f.amount > 0)
      } else {
        return this.brand.impacts.filter((f) => f.amount > 0)
      }
    },
    date(): string {
      return new Date(
        this.impactAction ? this.impactAction.action.createdAt : 0,
      ).toLocaleDateString(this.userLocale || 'en')
    },
    title(): TranslateResult {
      switch (this.impactAction?.type) {
        case 'ORDER_CREATED':
          return this.t('order_created', { sellerUserName: this.impactAction?.sellerUsername })
        case 'PER_SUBSCRIBER':
          return this.t('per_subscriber', { sellerUserName: this.impactAction?.sellerUsername })
        case 'PER_PRODUCT_REVIEW':
          return this.t('per_product_review', { sellerUserName: this.impactAction?.sellerUsername })
        case 'PER_COMPANY_REVIEW':
          return this.t('per_company_review', { sellerUserName: this.impactAction?.sellerUsername })
        case 'ONE_OFF':
          return this.t('one_off')
        case 'SUBSCRIPTION':
          return this.t('subscription')
        case 'REFERRAL':
          return this.t('referral')
        case 'INVOICE':
          return this.t('purchase')
        case 'GIFT':
          return this.t('gift')
        case 'REDEEM_LOYALTY_POINTS':
          return this.t('loyalty_points')
        default:
          return this.t('referral')
      }
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
    accountImpacts(): TotalImpact[] {
      return this.$store.getters['getAccountImpacts']
    },
  },
  created() {
    this.userImpacts = [...this.accountImpacts]
  },
  async mounted() {
    this.loading = true
    if (!this.isImpactFromGreenSpark) {
      const { data } = await getAccountImpactsByBrands(
        this.impactAction ? this.impactAction.sellerUserId : '',
      )
      this.brand = data
    }
    this.loading = false
  },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`ImpactActionDetailDialog.${key}`, params ?? {})
    },
    tc(key: string, amount?: number) {
      return this.$t(`ImpactActionDetailDialog.${key}`, amount ?? 0)
    },
    openSelectedBrand() {
      if (this.isImpactFromGreenSpark) {
        this.$emit('open-brand-detail')
      } else {
        const popup = document.getElementById('fullWidthPopup') as HTMLElement
        popup.scrollTo(0, 0)
        this.$emit('open-brand-detail', this.brand)
      }
    },
  },
  props: {
    impactAction: {
      type: Object as PropType<ImpactAction>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.impact-action-header {
  align-items: center;
  background: url('../../assets/backgrounds/brand-detail-background.svg') var(--ui-beige) no-repeat
    center/cover;
  border-radius: var(--border-radius-big);
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 40px;
  width: 100%;
}

.impact-action-icon {
  color: var(--ui-black);
  font-size: 60px;
  margin-bottom: 10px;
}

.impact-action-title {
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 10px;
  max-width: 25ch;
  text-align: center;
}

.date {
  color: var(--gray);
  font-size: 18px;
  line-height: 24px;
}

.dialog-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.info-container {
  background-color: var(--ui-beige);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-light);
  padding: 16px 20px;
  margin-bottom: 30px;
  width: 100%;
}

.product-info-title {
  align-items: center;
  color: var(--font-color-primary);
  display: flex;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}

.product-info-title-icon {
  color: inherit;
  font-size: inherit;
  margin-right: 5px;
}

.product-list {
  list-style-type: none;
  padding: 0;
}

.product-list-item {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.product-list-item:not(:last-of-type) {
  border-bottom: 1px solid var(--ui-green-light);
}

.product-info {
  align-items: center;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.product-image {
  border-radius: var(--border-radius-small);
}

.product-name {
  margin: 0;
}

.product-impacts {
  align-items: center;
  display: flex;
  justify-content: center;
}

.product-impact svg {
  height: 24px;
  margin-right: 5px;
  width: 24px;
}

.overall-impact-by-brand-title {
  align-items: center;
  color: var(--font-color-primary);
  display: flex;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
  width: 100%;
}

.overall-icon {
  font-size: 30px;
  color: var(--font-color-primary);
  flex-shrink: 0;
  height: 30px;
  margin-right: 5px;
  width: 30px;
}

.impact-by-brand-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 0;
}

.brand-logo {
  border-radius: 50%;
  object-fit: contain;
}

.impact-wrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;
}

.impact-by-brand {
  align-items: center;
  display: flex;
  margin: 0;
}

.impact-by-brand svg {
  height: 24px;
  margin-right: 5px;
  width: 24px;
}

button.info-container {
  position: relative;
  box-shadow: none;
  margin: 0;
  padding: 20px 20px;
  width: 100%;
}

button.info-container:hover {
  box-shadow: var(--box-shadow-light);
}

button.info-container::after {
  position: absolute;
  content: '';
  border-top: 2px solid var(--ui-green);
  border-right: 2px solid var(--ui-green);
  display: block;
  height: 12px;
  width: 12px;
  margin: 0 10px 0 auto;
  flex-shrink: 0;
  top: 50%;
  right: 10px;
  bottom: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
}

button.info-container:hover::after {
  opacity: 0;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

button.info-container::before {
  border-right: 2px solid var(--ui-green);
  border-top: 2px solid var(--ui-green);
  bottom: 50%;
  content: '';
  display: block;
  height: 12px;
  opacity: 0;
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 12px;
}

button.info-container:hover::before {
  opacity: 1;
  transform: translate(calc(-50% + 14px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

.view-details-text {
  display: none;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dialog-container {
    max-width: 770px;
    margin: 0 auto;
  }

  .product-list-item {
    align-items: center;
    flex-direction: row;
  }

  .product-info {
    flex: 1 1 50%;
    margin-bottom: 0;
  }

  .impact-by-brand-container {
    flex-direction: row;
    padding: 0 154px 0 0;
  }

  .impact-wrapper {
    justify-content: flex-start;
  }

  .overall-impact-by-brand-title {
    justify-content: flex-start;
  }

  .view-details-text {
    color: var(--ui-green);
    display: block;
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 55px;
    transform: translateY(-50%);
    margin: 0;
    height: 24px;
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
