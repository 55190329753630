import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b7dbf78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "popup-title" }
const _hoisted_3 = { class: "filter-button-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "impact-settings" }
const _hoisted_6 = { class: "markers" }
const _hoisted_7 = { class: "totals-wrapper section" }
const _hoisted_8 = { class: "cart-items-text" }
const _hoisted_9 = { class: "total-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactSettingCard = _resolveComponent("ImpactSettingCard")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_observe_intersection = _resolveDirective("observe-intersection")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    width: "1175",
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
    persistent: "",
    fullscreen: _ctx.$vuetify.display.xs
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.selectedProducts && _ctx.selectedProducts?.length > 1
              ? _ctx.t('choose_bulk_impact', { count: _ctx.selectedProducts?.length.toString() ?? '0' })
              : _ctx.t('choose_impact')), 1),
            _createElementVNode("button", {
              class: "close-button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"])),
              variant: "plain"
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
              return (_openBlock(), _createElementBlock("button", {
                key: index,
                class: _normalizeClass(['filter-button', { 'is-active': category === _ctx.selectedCategory }]),
                onClick: ($event: any) => (_ctx.searchByCategory(category))
              }, _toDisplayString(_ctx.t(`projects.${category}`)), 11, _hoisted_4))
            }), 128))
          ]),
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (project, index) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_ImpactSettingCard, {
                    key: index,
                    modelValue: _ctx.localValues[project.projectId],
                    "onUpdate:modelValue": [($event: any) => ((_ctx.localValues[project.projectId]) = $event), _ctx.onUpdate],
                    position: index,
                    "project-id": project.projectId,
                    "is-button-active": !!_ctx.localValues[project.projectId],
                    "max-value": _ctx.getMaxValueForProject(project.projectId)
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "position", "project-id", "is-button-active", "max-value"])), [
                    [_directive_observe_intersection, _ctx.setVisibleIndex, index]
                  ])
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (_, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(['marker-dot', { visible: index === _ctx.lastVisibleCardIndex }])
              }, null, 2))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.cartText), 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t('total', {
              price: _ctx.userCurrencySymbol + _ctx.total,
            })), 1)
          ]),
          _createVNode(_component_gs_button, {
            onClick: _ctx.handleSave,
            type: _ctx.automationId ? 'primary' : 'monochrome',
            capitalized: "",
            uppercased: false,
            size: _ctx.automationId ? 'large' : 'normal',
            "full-width": ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(_ctx.automationId ? 'save_impact' : 'set_impact')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "type", "size"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "fullscreen"]))
}