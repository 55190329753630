import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0aa51da5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "impact-icon" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "impact-amount mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['impact-pill', _ctx.theme, _ctx.impact.type])
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("use", {
        href: _ctx.iconIds[_ctx.impact.type]
      }, null, 8, _hoisted_2)
    ])),
    (_ctx.impact?.amount)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.formatTotalAmounts(_ctx.impact.amount)), 1))
      : _createCommentVNode("", true)
  ], 2))
}