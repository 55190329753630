import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-696fcb30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-list-item" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "impact-wrapper" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactPill = _resolveComponent("ImpactPill")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("button", {
      class: "order-list-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPopup && _ctx.openPopup(...args)))
    }, [
      _createElementVNode("img", {
        height: "60",
        width: "60",
        src: 
          _ctx.impactAction?.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')
        ,
        alt: _ctx.impactAction?.sellerUsername + 'logo',
        class: "company-logo",
        loading: "lazy"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.impactAction?.sellerUsername || 'Greenspark'), 1),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactAction?.action.impacts, (impact, index) => {
            return (_openBlock(), _createBlock(_component_ImpactPill, {
              key: index,
              impact: { type: impact.type || 'multiple', amount: impact.amount }
            }, null, 8, ["impact"]))
          }), 128))
        ])
      ])
    ])
  ]))
}