<template>
  <path
    d="M32.9658 24.5266C32.9658 23.4226 32.3971 22.4466 31.5318 21.8706C31.9218 21.3906 32.1533 20.7866 32.1533 20.1266C32.1533 18.5786 30.8818 17.3266 29.3096 17.3266C29.2689 17.3266 29.2283 17.3306 29.1877 17.3346C29.2649 17.0786 29.3096 16.8106 29.3096 16.5266C29.3096 14.9786 28.038 13.7266 26.4658 13.7266C24.8936 13.7266 23.6221 14.9786 23.6221 16.5266C23.6221 16.8066 23.6668 17.0786 23.7439 17.3346C23.7033 17.3346 23.6627 17.3266 23.6221 17.3266C22.0499 17.3266 20.7783 18.5786 20.7783 20.1266C20.7783 20.7866 21.0139 21.3946 21.3999 21.8706C20.5346 22.4466 19.9658 23.4186 19.9658 24.5266C19.9658 25.6346 20.5346 26.6066 21.3999 27.1826C21.0099 27.6626 20.7783 28.2666 20.7783 28.9266C20.7783 30.4746 22.0499 31.7266 23.6221 31.7266H29.3096C30.8818 31.7266 32.1533 30.4746 32.1533 28.9266C32.1533 28.2666 31.9177 27.6586 31.5318 27.1826C32.3971 26.6066 32.9658 25.6346 32.9658 24.5266Z"
    fill="white"
  />
  <path
    d="M29.8391 25.6704C29.6858 25.5211 29.4381 25.5211 29.2848 25.6704L27.2056 27.6959V22.1095C27.2056 21.8989 27.0287 21.7266 26.8125 21.7266C26.5963 21.7266 26.4195 21.8989 26.4195 22.1095V24.8242L24.635 23.0858C24.4817 22.9365 24.2341 22.9365 24.0808 23.0858C23.9275 23.2352 23.9275 23.4764 24.0808 23.6257L26.4234 25.9078V31.142L24.7372 29.4994C24.5839 29.35 24.3363 29.35 24.183 29.4994C24.0297 29.6487 24.0297 29.8899 24.183 30.0393L26.4273 32.2256V39.3437C26.4273 39.5543 26.6042 39.7266 26.8204 39.7266C27.0366 39.7266 27.2134 39.5543 27.2134 39.3437V28.7527C27.241 28.7374 27.2724 28.7221 27.296 28.6991L29.8509 26.2103C30.0041 26.061 30.0041 25.8197 29.8509 25.6704H29.8391Z"
    fill="white"
  />
</template>
