import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37c7fe04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "achievements-wrapper" }
const _hoisted_2 = { class: "achievements-header" }
const _hoisted_3 = { class: "levels" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "levels-text" }
const _hoisted_6 = { class: "achievements-inner" }
const _hoisted_7 = { class: "achievement" }
const _hoisted_8 = { class: "achievement-top-row" }
const _hoisted_9 = { class: "achievement-name" }
const _hoisted_10 = { class: "achievement-bottom-row" }
const _hoisted_11 = { class: "progress-bar" }
const _hoisted_12 = { class: "levels" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "levels-text" }
const _hoisted_15 = { class: "achievement-top-row" }
const _hoisted_16 = { class: "achievement-name" }
const _hoisted_17 = { class: "achievement-bottom-row" }
const _hoisted_18 = { class: "progress-bar" }
const _hoisted_19 = { class: "levels" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "levels-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.t('header')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "trophy",
          alt: "trophy",
          src: require('@/assets/icons/main-shape-dark.svg')
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.aggregatedLevel) + "/" + _toDisplayString(_ctx.maxLevel), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('months_earth_positive.label')), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_v_progress_linear, {
              color: "#47D18F",
              "bg-color": "#DBDBDB",
              rounded: "",
              modelValue: _ctx.earthPositivity.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.earthPositivity.value) = $event)),
              height: "29"
            }, {
              default: _withCtx(() => [
                _createElementVNode("strong", null, _toDisplayString(_ctx.earthPositivity.text), 1)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("img", {
              class: "trophy",
              alt: "trophy",
              src: require('@/assets/icons/main-shape-dark.svg')
            }, null, 8, _hoisted_13),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.earthPositivity.level) + "/7", 1)
          ])
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAchievementData, (achievementData, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "achievement"
        }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.t(`${achievementData.type}.label`)), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_v_progress_linear, {
                color: "#47D18F",
                "bg-color": "#DBDBDB",
                rounded: "",
                modelValue: achievementData.value,
                "onUpdate:modelValue": ($event: any) => ((achievementData.value) = $event),
                height: "29"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("strong", null, _toDisplayString(achievementData.text), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("img", {
                class: "trophy",
                alt: "trophy",
                src: require('@/assets/icons/main-shape-dark.svg')
              }, null, 8, _hoisted_20),
              _createElementVNode("div", _hoisted_21, _toDisplayString(achievementData.level) + "/" + _toDisplayString(achievementData.maxLevel), 1)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}