<template>
  <div class="impact-card-wrapper">
    <ul :class="['impact-card-list', theme]">
      <li
        v-for="(impact, index) in impacts"
        :key="index"
        class="impact-card"
        :style="{ backgroundImage: impactBackgrounds[impact.type] }"
      >
        <h3 class="impact-card-amount">
          {{ formatTotalAmounts(impact.amount) }}
        </h3>
        <p class="impact-card-label">
          {{ tc(impact.type, impact.amount) }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import type { Impact } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { IMPACT_BACKGROUND_ASSET_MAP } from '@/helpers/projects'
import { defineComponent } from 'vue'

type ImpactCardTheme = 'default' | 'light'
export default defineComponent({
  name: 'ImpactCards',
  mixins: [Utils],
  methods: {
    tc(key: string, amount?: number) {
      return this.$t(`ImpactCards.${key}`, amount ?? 0)
    },
  },
  props: {
    impacts: { type: Array as () => Impact[] },
    theme: { type: String as () => ImpactCardTheme, default: 'default' },
  },
  data() {
    return {
      impactBackgrounds: IMPACT_BACKGROUND_ASSET_MAP,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.impact-card-wrapper {
  width: 100%;
  max-width: 770px;
}

.impact-card-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 30px;
  padding: 0;
  background-color: transparent;
}

.impact-card {
  align-items: center;
  background-color: var(--ui-beige);
  border-radius: var(--border-radius-big);
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.impact-card-list.light .impact-card {
  background-color: var(--ui-white);
}

.impact-card-amount {
  color: var(--ui-black);
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 5px;
}

.impact-card-label {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
  white-space: nowrap;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .impact-card {
    padding: 30px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .impact-card-wrapper {
    padding: 10px;
  }

  .impact-card-list {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0 -10px 30px;
  }

  .impact-card-list.light {
    margin: 0 -10px 0;
  }

  .impact-card {
    flex-grow: 0;
    padding: 16px 20px;
    margin: 0 10px 20px;
    width: calc(50% - 20px);
  }

  .impact-card-amount {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 3px;
  }

  .impact-card-label {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}
</style>
