import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95992f88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-trigger" }
const _hoisted_2 = { class: "automation-name-wrapper" }
const _hoisted_3 = { class: "label-wrapper" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "select-trigger-wrapper" }
const _hoisted_8 = { class: "label choose" }
const _hoisted_9 = { class: "select-trigger-list" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "trigger-title" }
const _hoisted_12 = { class: "trigger-name" }
const _hoisted_13 = { class: "trigger-description" }
const _hoisted_14 = {
  key: 0,
  class: "notification-text"
}
const _hoisted_15 = {
  key: 0,
  class: "markers"
}
const _hoisted_16 = { class: "form-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_step_summary = _resolveComponent("step-summary")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_select_shopify_order_origin = _resolveComponent("select-shopify-order-origin")!
  const _component_select_form_impact_source = _resolveComponent("select-form-impact-source")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_CallToUpgradePopup = _resolveComponent("CallToUpgradePopup")!
  const _directive_observe_intersection = _resolveDirective("observe-intersection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_step_summary, {
      label: _ctx.t('integration'),
      "previous-answers": [_ctx.getIntegrationPlatformName(_ctx.platform) || '', _ctx.selectedIntegration?.name]
    }, null, 8, ["label", "previous-answers"]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('name_automation')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('automation_tooltip')), 1)
      ]),
      _createVNode(_component_gs_input, {
        "model-value": _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = (v) => _ctx.$emit('update:name', v)),
        class: "automation-name-input",
        label: _ctx.t('automation_name'),
        "hide-details": false,
        rules: [_ctx.rules.required],
        error: _ctx.inputError
      }, null, 8, ["model-value", "label", "rules", "error"])
    ]),
    (_ctx.platform === 'shopify')
      ? (_openBlock(), _createBlock(_component_select_shopify_order_origin, {
          key: 0,
          class: "select-order-origin-wrapper",
          "model-value": _ctx.shopifyOrderOrigin,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = (v) => _ctx.$emit('update:shopify-order-origin', v)),
          "integration-name": _ctx.platform
        }, null, 8, ["model-value", "integration-name"]))
      : _createCommentVNode("", true),
    (_ctx.showFormImpactSource)
      ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
          _createVNode(_component_select_form_impact_source, {
            "model-value": _ctx.formImpactSource,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = (v) => _ctx.$emit('update:form-impact-source', v)),
            error: _ctx.formImpactSourceError,
            platform: _ctx.platform
          }, null, 8, ["model-value", "error", "platform"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('choose_trigger')), 1),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.triggers, (trigger, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("li", {
            key: index,
            class: "select-trigger-list-item"
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(['select-trigger-button', { 'is-active': _ctx.activeTrigger === trigger }]),
              onClick: () => _ctx.selectTrigger(trigger)
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_v_icon, { class: "trigger-icon material-icons-outlined" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getAutomationIcon(trigger)), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t(_ctx.snakeCase(trigger))), 1)
              ]),
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t(`description_${_ctx.snakeCase(trigger)}`)), 1),
              (!_ctx.isTriggerAllowed(trigger))
                ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.t('notification_text', {
                  planName: _ctx.getPlanName(_ctx.activePaidSubscriptionItem.product) || '',
                })), 1))
                : _createCommentVNode("", true)
            ], 10, _hoisted_10)
          ])), [
            [_directive_observe_intersection, _ctx.setVisibleIndex, index]
          ])
        }), 128))
      ]),
      (_ctx.triggers.length > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.triggers, (_, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(['marker-dot', { visible: index === _ctx.lastVisibleCardIndex }])
              }, null, 2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_gs_button, {
        capitalized: "",
        muted: "",
        outlined: "",
        type: "secondary-v2",
        size: "large",
        class: "form-control",
        uppercased: false,
        icon: "mdi-chevron-left",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('back')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.back')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_gs_button, {
        "full-width": "",
        capitalized: "",
        uppercased: false,
        type: "secondary-v2",
        size: "large",
        class: "form-control",
        disabled: _ctx.hasFormErrors,
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('submit')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.next')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    (_ctx.isCallToUpgradePopupOpen)
      ? (_openBlock(), _createBlock(_component_CallToUpgradePopup, {
          key: 2,
          "is-premium-only": false,
          "popup-type": "trigger",
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false))
        }))
      : _createCommentVNode("", true)
  ]))
}