<template>
  <div class="achievements-wrapper">
    <div class="achievements-header">
      <div>{{ t('header') }}</div>
      <div class="levels">
        <img class="trophy" alt="trophy" :src="require('@/assets/icons/main-shape-dark.svg')" />
        <div class="levels-text">{{ aggregatedLevel }}/{{ maxLevel }}</div>
      </div>
    </div>
    <div class="achievements-inner">
      <div class="achievement">
        <div class="achievement-top-row">
          <div class="achievement-name">
            {{ t('months_earth_positive.label') }}
          </div>
        </div>
        <div class="achievement-bottom-row">
          <div class="progress-bar">
            <v-progress-linear
              color="#47D18F"
              bg-color="#DBDBDB"
              rounded
              v-model="earthPositivity.value"
              height="29"
            >
              <template v-slot>
                <strong>{{ earthPositivity.text }}</strong>
              </template>
            </v-progress-linear>
          </div>
          <div class="levels">
            <img class="trophy" alt="trophy" :src="require('@/assets/icons/main-shape-dark.svg')" />
            <div class="levels-text">{{ earthPositivity.level }}/7</div>
          </div>
        </div>
      </div>

      <div v-for="(achievementData, index) in getAchievementData" :key="index" class="achievement">
        <div class="achievement-top-row">
          <div class="achievement-name">
            {{ t(`${achievementData.type}.label`) }}
          </div>
        </div>
        <div class="achievement-bottom-row">
          <div class="progress-bar">
            <v-progress-linear
              color="#47D18F"
              bg-color="#DBDBDB"
              rounded
              v-model="achievementData.value"
              height="29"
            >
              <template v-slot>
                <strong>{{ achievementData.text }}</strong>
              </template>
            </v-progress-linear>
          </div>
          <div class="levels">
            <img class="trophy" alt="trophy" :src="require('@/assets/icons/main-shape-dark.svg')" />
            <div class="levels-text">
              {{ achievementData.level }}/{{ achievementData.maxLevel }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { OFFSET_TYPES } from '@/helpers/interfaces'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

const ACHIEVEMENT_TYPES = [...OFFSET_TYPES, 'months_earth_positive']
export type AchievementTypes = (typeof ACHIEVEMENT_TYPES)[number]

export interface AchievementData {
  level: number
  text: TranslateResult
  value: number
  maxLevel: number
  type: AchievementTypes
}
export default defineComponent({
  name: 'Achievements',
  computed: {
    earthPositivity(): AchievementData {
      const earthPositivity: AchievementData = {
        level: 0,
        text: '',
        value: 0,
        maxLevel: 7,
        type: 'months_earth_positive',
      }
      if (this.monthsEarthPositive < 3) {
        earthPositivity.level = 0
        earthPositivity.value = (this.monthsEarthPositive / 4) * 100
      } else if (this.monthsEarthPositive >= 3 && this.monthsEarthPositive < 6) {
        earthPositivity.level = 1
        earthPositivity.value = (this.monthsEarthPositive / 8) * 100
      } else if (this.monthsEarthPositive >= 6 && this.monthsEarthPositive < 9) {
        earthPositivity.level = 2
        earthPositivity.value = (this.monthsEarthPositive / 8) * 100
      } else if (this.monthsEarthPositive >= 9 && this.monthsEarthPositive < 12) {
        earthPositivity.level = 3
        earthPositivity.value = (this.monthsEarthPositive / 12) * 100
      } else if (this.monthsEarthPositive >= 12 && this.monthsEarthPositive < 24) {
        earthPositivity.level = 4
        earthPositivity.value = (this.monthsEarthPositive / 24) * 100
      } else if (this.monthsEarthPositive >= 24 && this.monthsEarthPositive < 48) {
        earthPositivity.level = 5
        earthPositivity.value = (this.monthsEarthPositive / 48) * 100
      } else if (this.monthsEarthPositive >= 48 && this.monthsEarthPositive < 72) {
        earthPositivity.level = 6
        earthPositivity.value = (this.monthsEarthPositive / 72) * 100
      } else {
        earthPositivity.level = 7
        earthPositivity.value = 100
      }
      earthPositivity.text = this.t(`months_earth_positive.level_${earthPositivity.level}`, {
        current: this.monthsEarthPositive,
        flavor: this.t('months_earth_positive.flavor'),
      })
      return earthPositivity
    },
    aggregatedLevel(): number {
      return this.getAchievementData.reduce(
        (acc, curr) => acc + curr.level,
        this.earthPositivity.level,
      )
    },
    maxLevel(): number {
      return this.getAchievementData.reduce((acc, curr) => acc + curr.maxLevel, 7)
    },
    monthsEarthPositive(): number {
      return this.$store.getters['getMonthsEarthPositive']
    },
    getAchievementData(): AchievementData[] {
      return this.$store.getters['getAchievementData']
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`Achievements.${key}`, params ?? {})
    },
  },
})
</script>

<style lang="scss" scoped>
hr {
  opacity: 0.2;
  margin-bottom: 10px;
  margin-top: 18px;
}

.achievements-wrapper {
  display: flex;
  flex-direction: column;
  background: var(--ui-white);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 4px 4px 0px #0000001a;
}

.achievements-header {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.achievements-inner {
  overflow: auto;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.achievement-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.achievement-name {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}

.levels {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50px;
}

.achievements-header .levels .levels-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.achievements-header .levels .trophy {
  height: 18px;
}

.trophy {
  margin-right: 5px;
  height: 15px;
}

.levels-text {
  display: flex;
  align-items: flex-end;
  color: #212121;
  font-weight: 500;
  font-size: 14px;
}

.achievement-bottom-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  flex: 1;
}

:deep(.v-progress-linear--rounded) {
  border-radius: 8px;
}

:deep(.v-progress-linear__determinate) {
  border-radius: 8px;
}

:deep(.v-progress-linear__background) {
  width: 100% !important;
  left: 0 !important;
}

:deep(.v-progress-linear__content) {
  justify-content: start;
  margin-left: 9px;
  font-family: Cabin;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .achievements-wrapper {
    height: 100%;
  }
}
</style>
