<template>
  <div v-if="loaded">
    <div class="impact-wrapper">
      <SmallButton
        :label="t('add_impact')"
        :on-click="addImpact"
        :type="'green'"
        :disabled="impactArray.length > 2"
      />
      <SmallButton
        :label="t('remove_impact')"
        :on-click="removeImpacts"
        :type="'red'"
        :disabled="impactArray.length < 2"
      />
    </div>

    <transition v-for="(item, index) in impactArray" :key="index" name="fade">
      <div class="select">
        <v-select
          v-model="item.type"
          :items="items"
          :label="t('impact')"
          variant="outlined"
          color="#3B755F"
          item-color="#3B755F"
          class="dropdown"
          :rules="[rules.required]"
        />

        <div class="text-field-wrapper">
          <v-text-field
            v-model.number="item.amount"
            :label="t('amount')"
            variant="outlined"
            clearable
            type="number"
            flat
            min="0"
            color="#3B755F"
            class="employee-input"
            :rules="[rules.required]"
          />
          <div class="plus-minus">
            <div class="add" style="margin-bottom: 1px" @click="item.amount++">+</div>
            <div class="add" style="margin-top: 1px" @click="item.amount--">-</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import SmallButton from '@/components/form/SmallButton.vue'
import type { Impact, OffsetType } from '@/helpers/interfaces'
import { SUBSCRIPTION_OFFSET_TYPES } from '@/helpers/interfaces'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'ImpactInput',
  emits: ['setImpactType'],
  components: {
    SmallButton,
  },
  mixins: [RulesMixin],
  data() {
    return {
      impactArray: [],
      loaded: false,
    } as {
      impactArray: Impact[]
      loaded: boolean
    }
  },
  computed: {
    items(): {
      title: TranslateResult
      value: 'trees' | 'carbon' | 'plastic'
    }[] {
      return SUBSCRIPTION_OFFSET_TYPES.map((type) => {
        return {
          title: this.t(type, { price: this.getFormattedDefaultProjectPriceByType(type) }),
          value: type,
        }
      })
    },
    getFormattedDefaultProjectPriceByType(): (type: OffsetType) => string {
      return this.$store.getters['getFormattedDefaultProjectPriceByType']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  created() {
    if (this.isEdit) {
      this.checkAndSetActive()
    }
    this.loaded = true
  },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`ImpactInput.${key}`, params ?? {})
    },
    addImpact() {
      this.impactArray.push({ type: 'trees', amount: 1 })
    },
    removeImpacts() {
      this.impactArray.pop()
    },
    checkAndSetActive() {
      this.impactArray = this.impactArraySet
    },
    onImpactChange() {
      this.$emit(
        'setImpactType',
        this.impactArray.filter(({ type }) => !!type),
      )
    },
    onResetTypeChange() {
      this.impactArray = []
    },
  },
  props: {
    impactArraySet: {
      type: Array as PropType<Impact[]>,
      required: true,
      default: () => {
        return []
      },
    },
    isEdit: {
      type: Boolean,
    },
    resetType: {
      type: Boolean,
    },
  },
  watch: {
    impactArray: [
      {
        deep: true,
        handler: 'onImpactChange',
      },
    ],
    resetType: [
      {
        handler: 'onResetTypeChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.header-close {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.subheader {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.close-wrapper {
  cursor: pointer;
}

.text {
  font-size: 18px;
}

.impact-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 25px;
}

.select {
  margin-top: 25px;
  display: flex;
}

.dropdown {
  width: 100px;
}

.text-field-wrapper {
  display: flex;
  width: 160px;
  min-width: 160px;
  margin-left: 10px;
  margin-right: 10px;
}

.plus-minus {
  min-width: 10%;
  cursor: pointer;
  height: 56px;
  margin-left: 6px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-weight: 600;
  background: #3b755f;
  font-size: 18px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.basket-value {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.total-impact {
  text-align: right;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 500;
  opacity: 50%;
}

.total-text {
  text-align: right;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
}

.button {
  margin-top: 35px;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 16px;
}

.cancel-text {
  margin-top: 12px;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
}

@media screen and (max-width: 485px) {
  .select {
    flex-direction: column;
  }

  .dropdown {
    width: 100%;
  }

  .text-field-wrapper {
    margin-top: 15px;
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }

  .basket-value {
    margin-top: 0;
  }

  .plus-minus {
    min-width: 0;
  }
}
</style>
